export default {
  "ques1": {
    quesType: "Skin Type",
    quesTitle: "What kind of skin you have?",
    options: {
      dry: "Dry",
      oily: "Oily",
      combination: "Combination",
      sensitive: "Sensitive",
      normal: "Normal"
    }
  },
  "ques2": {
    quesType: "Gender",
    options: {
      male: "Male",
      female: "Female",
      others: "Others"
    }
  },
  "ques3": {
    quesType: "Age",
    quesTitle: "How old are you?",
  },
  "ques4": {
    quesType: "Photo",
    quesTitle: "It’s time to choose pic!",
    selfieBtn: "Take a selfie",
    galleryBtn: "Choose from gallery"
  },
  "ques5": {
    quesType: "Email",
    quesTitle: "Share your email with us",
    placeholder: "Enter Email id"
  },
  "ques6": {
    quesTitle: "Have you bought any product of Lakmé Salon?",
  },
  or: 'Or',
  submit: "Submit",
  guest: "Continue as Guest",
  next: "Next",
  capture: "Capture",
  captureAgain: "Capture Again",
  gallery: "Or, choose one from the gallery",
  resultTitle: "Result",
  healthScore: "Skin Health score",
  analysisTitle: "Overall Analysis",
  buy: "Information",
  clean: "Clean",
  day: "Day Routine",
  night: "Night Routine",
  weekly: "Weekly",
  analysisName: {
    "dark_circle": "Dark circle",
    "skin_dullness": "Dullness",
    "dark_spots": "Dark Spots",
    "acne": "Acne",
    "uneven_skin": "Uneven Skin Tone",
    "face_wrinkles": "Face Wrinkle",
    "eye_wrinkles": "Eye Wrinkle",
    "crows_feet": "Crows Feet",
    "shine": "shine",
    "redness": "Redness",
    "firmness": "Firmness",
    "oxygen": "Oxygen",
    "smoothness": "Smoothness",
    "hydration": "Hydration",
    "skin_health": "Skin Health",
    "skin_age": "Skin Age",
    "perceived_age": "Perceived Age",
    "pigmentation": "Pigmentation",
    "texture": "Texture"
  },
  "recommendationTitle": "Products Recommended",
  "camIns": `Click the picture in natural light and keep a neutral expression`,
  "Priority": "Priority",
  "High": "High",
  "Medium": "Medium",
  "Low": "Low",
  "back": "Back",
  "routine": "Routine"
};