import React from "react";
// import CircularProgress from '@mui/material/CircularProgress';
import {
  CircularProgressbar, buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useTranslation from "../../hooks/LanguageHooks";
import CleanHandsOutlinedIcon from '@mui/icons-material/CleanHandsOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Feedback from "../Feedback";
// import IframePop from "../IframePop";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ResultStyle from './style';


function Result({ analysisResult }) {
  const translation = useTranslation();
  const [expanded, setExpanded] = React.useState('panel0');
  // const [showIframe, setShowIframe] = React.useState(false);
  // const [prodLink, setProdLink] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isMobileDevice = () => {
    let check = false;
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   check = true;
    // }
    return check;
  };

  const checkIframe = (productLink) => {
    // setShowIframe(true);
    // setProdLink(productLink);
    if (isMobileDevice()) {
      // eslint-disable-next-line no-undef
      app.changeActivity(productLink);
    } else {
      window.open(productLink, '_blank');
    }
  }

  // const closeIframe =() => {
  //   setShowIframe(false);
  // }

  const setColorBand = (riskLevel) => {
    if (riskLevel === 1) {
      return '#acfc8f';
    } if (riskLevel === 2) {
      return '#fdee8b';
    } if (riskLevel === 3) {
      return '#fc5656'
    }
  }

  const getAdjustedConcernValue = (value, concernName) => {
    if (["skin_health", "hydration", "smoothness", "firmness", "oxygen"].includes(concernName.toLowerCase())) {
      return value;
    } else {
      return 100 - value;
    }
  };

  return (
    <ResultStyle>
      <div className="titleWrapper">
        <div className="pageTitle">{translation.resultTitle}</div>
        <div className="resultWrapper">
          <div className="resultImg">
            <img src={analysisResult && analysisResult.input_url} alt="" onError={(e) => e.target.src = "./fallback-product.svg"}></img>
          </div>
        </div>
        <div className="analysisTitle">{translation.analysisTitle}</div>
        <div className="analysisWrapper">
          {analysisResult.recommendation.sort((a, b) => {
            if (b.risk_level === a.risk_level) {
              return getAdjustedConcernValue(b.value, b.concern_name) - getAdjustedConcernValue(a.value, a.concern_name);
            }
            return b.risk_level - a.risk_level;
          }).map((keyname, i) => (
            <div className="analysisInnerWrap" key={i}>
              <div className="analysisScore">
                <CircularProgressbar
                  value={getAdjustedConcernValue(keyname.value, keyname.concern_name)}
                  text={`${getAdjustedConcernValue(keyname.value, keyname.concern_name)}%`}
                  strokeWidth={5}
                  styles={buildStyles({
                    textColor: '#212727',
                    textSize: '35px',
                    pathColor: setColorBand(keyname.risk_level),
                    trailColor: '#212727'
                  })}
                />
              </div>
              <div className="analysisText">{translation.analysisName[keyname.concern_name.toLowerCase()]}</div>
            </div>
          ))
          }
        </div>
        <div className="priorityText">
          <div className="priorityBox">{translation.Priority}:
            <span className="colorCodeRed"></span> <span>{translation.High}</span><span style={{ marginLeft: '10px' }}>|</span>
            <span className="colorCodeYellow"></span> <span>{translation.Medium}</span><span style={{ marginLeft: '10px' }}>|</span>
            <span className="colorCodeGreen"></span> <span>{translation.Low}</span>
          </div>
        </div>
        <div style={{ margin: "25px 0 20px" }} className="pageTitle">{translation.recommendationTitle}</div>
        <Accordion expanded={expanded === `panel0`} onChange={handleChange(`panel0`)} className="accoWrap">
          <AccordionDetails>
            <div className="recommendedProductWrapper" >
              {analysisResult && analysisResult.recommendation.sort((a, b) => {
                if (b.risk_level === a.risk_level) {
                  return b.value - a.value;
                }
                return b.risk_level - a.risk_level;
              }).map((recommendation) =>
              (
                recommendation.products && recommendation.products.length > 0 && (
                  <div key={recommendation.concern_name}>
                    <Typography variant="h6">{translation.analysisName[recommendation.concern_name.toLowerCase()]}</Typography>
                    <div className="product-list">
                      {recommendation.products.map((product) => (
                        <div key={product.sku_code} className="productInnerWrap">
                          <div className="productImg">
                            <img src={product.image_url} alt="" onError={(e) => e.target.src = "./fallback-product.svg"} />
                          </div>
                          <div className="productTitle">{product.title}</div>
                          <div className="productPrice">{product.price}</div>
                          {/* <button onClick={() => checkIframe(product.link)} className="buyBtn">{translation.buy}</button> */}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* <Feedback /> */}
      {/* <div style={{margin: "0 0 20px", textAlign: "center"}}><button onClick={() => {window.location.reload()}} className="buyBtn">{translation.back}</button></div> */}
      {/* <IframePop pLink={prodLink} closeIframe={closeIframe} /> */}
    </ResultStyle>
  )
}

export default Result;