import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import LoaderStyle from "./style";



function Loader () {
  return(
    <LoaderStyle>
      <CircularProgress className="loaderBody" />
    </LoaderStyle>
  )
}

export default Loader;