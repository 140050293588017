import axios from "axios";
import { Config } from "../config";

const _data = {
  "success": true,
  "statusCode": 200,
  "message": "",
  "data": {
    "input_url": "https://skinanalysis-prod.s3.ap-south-1.amazonaws.com/5bb7b982-98f9-4e25-8857-09c826594708-input.jpg",
    "recommendation": [
      {
        "concern_name": "DARK_CIRCLE",
        "risk_level": 2,
        "value": 71,
        "products": []
      },
      {
        "concern_name": "SKIN_DULLNESS",
        "risk_level": 2,
        "value": 71,
        "products": [
          {
            "title": "SPF 50+ Fragrance Free Face Sunscreen Lotion",
            "sku_code": "8",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/8.jpg",
            "price": "$31.99"
          },
          {
            "title": "Clarifying Lotion 1.0 Twice A Day Exfoliator",
            "sku_code": "9",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/9.jpg",
            "price": "$22.00"
          }
        ]
      },
      {
        "concern_name": "DARK_SPOTS",
        "risk_level": 1,
        "value": 89,
        "products": []
      },
      {
        "concern_name": "ACNE",
        "risk_level": 1,
        "value": 97,
        "products": []
      },
      {
        "concern_name": "UNEVEN_SKIN",
        "risk_level": 2,
        "value": 69,
        "products": [
          {
            "title": "Saccharomyces Ferment 30% Milky Toner",
            "sku_code": "4",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/4.jpg",
            "price": "$14.00"
          },
          {
            "title": " Daily Moisturizing Oil Mist with Oat Oil",
            "sku_code": "5",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/5.jpg",
            "price": "$15.79"
          },
          {
            "title": "SPF 50+ Fragrance Free Face Sunscreen Lotion",
            "sku_code": "7",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/7.jpg",
            "price": "$14.95"
          },
          {
            "title": "SPF 50+ Fragrance Free Face Sunscreen Lotion",
            "sku_code": "8",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/8.jpg",
            "price": "$31.99"
          },
          {
            "title": "Clarifying Lotion 1.0 Twice A Day Exfoliator",
            "sku_code": "9",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/9.jpg",
            "price": "$22.00"
          },
          {
            "title": "Deep Action Exfoliating Cleanser",
            "sku_code": "10",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/10.jpg",
            "price": "$8.99"
          }
        ]
      },
      {
        "concern_name": "FACE_WRINKLES",
        "risk_level": 2,
        "value": 73,
        "products": []
      },
      {
        "concern_name": "EYE_WRINKLES",
        "risk_level": 1,
        "value": 88,
        "products": []
      },
      {
        "concern_name": "CROWS_FEET",
        "risk_level": 2,
        "value": 74,
        "products": []
      },
      {
        "concern_name": "SHINE",
        "risk_level": 2,
        "value": 43,
        "products": []
      },
      {
        "concern_name": "REDNESS",
        "risk_level": 2,
        "value": 67,
        "products": [
          {
            "title": "SPF 50+ Fragrance Free Face Sunscreen Lotion",
            "sku_code": "8",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/8.jpg",
            "price": "$31.99"
          },
          {
            "title": "Clarifying Lotion 1.0 Twice A Day Exfoliator",
            "sku_code": "9",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/9.jpg",
            "price": "$22.00"
          }
        ]
      },
      {
        "concern_name": "PIGMENTATION",
        "risk_level": 1,
        "value": 86,
        "products": []
      },
      {
        "concern_name": "FIRMNESS",
        "risk_level": 2,
        "value": 60,
        "products": []
      },
      {
        "concern_name": "OXYGEN",
        "risk_level": 2,
        "value": 75,
        "products": []
      },
      {
        "concern_name": "SMOOTHNESS",
        "risk_level": 1,
        "value": 82,
        "products": []
      },
      {
        "concern_name": "HYDRATION",
        "risk_level": 2,
        "value": 49,
        "products": [
          {
            "title": "Cicaplast Baume B5+ 40ml",
            "sku_code": "6",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/6.jpg",
            "price": "$16.99"
          },
          {
            "title": "SPF 50+ Fragrance Free Face Sunscreen Lotion",
            "sku_code": "8",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/8.jpg",
            "price": "$31.99"
          },
          {
            "title": "Clarifying Lotion 1.0 Twice A Day Exfoliator",
            "sku_code": "9",
            "image_url": "https://client-skincare-products.s3.ap-south-1.amazonaws.com/gHna/products/9.jpg",
            "price": "$22.00"
          }
        ]
      },
      {
        "concern_name": "SKIN_HEALTH",
        "risk_level": 2,
        "value": 77,
        "products": []
      },
      {
        "concern_name": "TEXTURE",
        "risk_level": 2,
        "value": 69,
        "products": []
      }
    ]
  }
};

export async function fetchResult(data) {
  // return Promise.resolve(_data);
  // const image = await b64toBlob(data);
  // console.log("image", image);

  try {
    const response = await axios.post(Config.testUrl, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    return response.data;
  }
  catch (error) {
    // console.log(error.response.data);
    return error.response.data.error;
  }
}


// const b64toBlob = (base64) => fetch(base64).then(res => res.blob());
export const b64toBlob = (base64) => {
  const date = new Date().valueOf();
  let text = '';
  const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 5; i++) {
    text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
  }
  // Replace extension according to your media type
  const imageName = date + '.' + text + '.jpeg';
  const imageBlob = base64.replace(/^data:image\/[a-z]+;base64,/, "");
  const byteString = window.atob(imageBlob);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/jpeg' });
  const file = new File([blob], imageName, { type: 'image/jpeg' });
  return file;
}

export async function postProductSold(id, body) {
  try {
    const response = await axios.patch(Config.productsoldUrl(id), body,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    return response.data;
  }
  catch (error) {
    // console.log(error.response.data);
    return error.response.data.error;
  }
}