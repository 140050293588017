import React from 'react';
import { Form, Field } from "react-final-form";
import MenuItem from '@mui/material/MenuItem';
import useTranslation from "../../hooks/LanguageHooks";
import Select from '@mui/material/Select';
import {postProductSold} from '../../services/analysisService';
import FeedbackStyle from './style';

function Feedback() {
  const translation = useTranslation();
  const [soldItem, setSoldItem] = React.useState(0);

  const selectProductsold = (e) => {
    setSoldItem(e.target.value)
  }

  const submit = (value) => {
    let id = localStorage.getItem('id');
    let data = {
      email: value.email,
      productSold: soldItem
    }
    postProductSold(id, data).then(res => {
      localStorage.clear();
      window.location.reload();
    })
  }

  return(
    <FeedbackStyle>
        <div className="formWrapper">
          <div className="soldQuesText">{translation.ques6.quesTitle}</div>
          <Form
            onSubmit={submit}
            initialValues={{}}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} >
                <div className="quesWrapper">
                    <Field
                      className="ClientInput"
                      name="email"
                      component="input"
                      type="email"
                      placeholder={translation.ques5.placeholder}
                    />
                </div>
                <div className="quesWrapper">
                <Field>
                    {({ input, meta }) => (
                      
                      <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={soldItem}
                      className="soldInput"
                      name="productSold"
                      onChange={selectProductsold}
                    >
                      {[0,1,2,3].map((ele, idx) => <MenuItem className="optionItems" key={idx} value={ele}>{ele}</MenuItem>)}
                    </Select>
                    )}
                  </Field>
                </div>
                <div className="nextBtn">
                    <button
                      className="SubmitBtn"
                      type="submit"
                    >
                      {translation.submit}
                    </button>
                  </div>
              </form>
            )}
          </Form>
        </div>
    </FeedbackStyle>
  )
}

export default Feedback;