export default {
  "ques1": {
    quesType: "Tipo de piel",
    quesTitle: "¿Qué tipo de piel tienes?",
    options: {
      dry: "Normal Seca",
      oily: "Normal Grasa",
      mixed: "Mixta"
    }
  },
  "ques2": {
    quesType:"¿Qué preocupaciones tienes?",
    quesTitle: "¿Qué te preocupa sobre el cuidado de tu piel?",
    quesSubtitle: "Puede selecionar más de una opción",
    options: {
      dark_circle: "Bolsas y ojeras",
      wrinkles: "Arrugas",
      neckline: "Cuello y Escote",
      "luminosity-flaccidity": "Flacidez y Luminosidad",
      blemishes: "Manchas",
      acne: "Piel acneica",
      redness: "Piel con tendencias al enrojecimiento"
    }
  },
  "ques3": {
    quesType: "Edad",
    quesTitle: "¿Cuántos años tienes?",
  },
  "ques4": {
    quesType:  "Captura de imagen",
    quesTitle: "¡Es hora de hacerte una selfie!",
  },
  "ques5": {
    quesType:  "Correo electrónico",
    quesTitle: "Compártenos tu mail",
    placeholder: "Ingrese la identificación del correo electrónico"
  },
  "ques6": {
    quesTitle: "¿Has comprador algun product de Lakmé Salon?",
  },
  or: 'O',
  submit: "Enviar",
  guest: "Continuar como invitado",
  next: "Siguiente",
  capture: "Hacer foto",
  captureAgain: "Capturar de nuevo",
  gallery: "O, escoge una de la galería de fotos",
  resultTitle: "Resultado",
  healthScore: "Índice de la salud de tu piel",
  analysisTitle: "Análisis general",
  buy: "Información",
  clean: "Limpieza",
  day: "Rutina de Día",
  night: "Rutina de noche",
  weekly: "Semanalmente",
  analysisName: {
    "dark_circle": "Ojeras",
    "skin_dullness": "Flacidez",
    "dark_spots": "Manchas oscuras",
    "acne": "Piel acneica",
    "uneven_skin": "Tono desigual de la piel",
    "face_wrinkles": "Arrugas de la cara",
    "eye_wrinkles": "Arrugas en los ojos",
    "crows_feet": "Patas de gallo",
    "shine": "Brillar",
    "redness": "Enrojecimiento",
    "firmness": "Firmeza",
    "oxygen": "Oxígeno",
    "smoothness": "Suavidad",
    "hydration": "Hidratación",
    "skin_health": "Salud de la piel",
    "skin_age": "Edad de la piel",
    "perceived_age": "Edad percibida",
    "pigmentation": "Pigmentación"
  },
  "recommendationTitle": "Productos recomendados",
  "camIns" :"Haga clic en la imagen con luz natural y mantenga una expresión neutral.",
  "Priority": "Prioridad",
  "High": "Alto",
  "Medium" : "Medio",
  "Low": "Bajo",
  "back": "Ir al inicio",
  "routine": "Rutina"
};