import styled from 'styled-components';

const FeedbackStyle = styled.div`
  background: #000;
  padding: 50px 20px;

  .soldQuesText {
    font-weight: 700;
    font-size: 2rem;
    line-height: 50px;
    color: #eb008b;
    font-family: 'Mabry';
  }
  .soldInput{
    font-family: 'Mabry' !important;
    width: 430px;
    height: 50px;
    background: #F8F4EE;
    border: none;
    padding: 15px;
    border-radius: 15px;
    font-size: 1.2rem;
    color: #2F2F2F;
    margin-top: 60px;
    &:focus-visible {
      border: none;
      outline: none;
      -webkit-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      -moz-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
    }
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
  }
  .ClientInput {
    font-family: 'Mabry' !important;
    width: 400px;
    height: 50px;
    background: #F8F4EE;
    border: none;
    padding: 0 15px;
    border-radius: 15px;
    font-size: 1.2rem;
    color: #2F2F2F;
    margin-top: 60px;
    &:focus-visible {
      border: none;
      outline: none;
      -webkit-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      -moz-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
    }
  }
`;

export default FeedbackStyle;