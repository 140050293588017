import styled from "styled-components";

const LoaderStyle = styled.div`

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgb(51,51,51,0.4);
  height: 100%;
  width: 100%;
  margin: 0;

.loaderBody {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
},

`;

export default LoaderStyle;