import styled from 'styled-components';

const ResultStyle = styled.div`
  .resultImg {
    width: 150px;
    border-radius: 4px;
    img {
      width: inherit;
      height: inherit;
      border-radius: 10px;
    }
  }
  .resultWrapper {
    padding-top: 25px;
    padding-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .scoreWrapper {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .healthText {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 15px;
    color: #212727;
  }
  .mainScore{
    width: 100px;
    // height: 100px;
  }
  .analysisScore{
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
  }
  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke-width: 0.5px;
      // color: #eb008b;
    }
  }
  .analysisTitle {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 17px;
    color: #FFFFFF;
  }
  .analysisText {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 20px;
    color: #212727;
    white-space: break-spaces;
  }
  .analysisInnerWrap{
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    align-items: center;
    margin: 7px 10px 7px 0;
    // gap: 10px;
  }
  .analysisWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    // gap: 15px;
    margin: 0 0 5px 0;
    padding: 15px;
  }
  .recommendedProductWrapper {
    margin: 5px 0;
    padding: 15px;
    overflow: auto;
  }
  .product-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
  }
  .productTitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 15px;
    color: #2F2F2F;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .productPrice {
    font-weight: 600;
    font-size: 0.8rem;
  }
  .productDesc {
    font-weight: 400;
    font-size: 6px;
    line-height: 7px;
    color: #2F2F2F;
  }
  .productImg{
    width: 68px;
    height: 80px;
    margin-bottom: 10px;
    // background: #D9D9D9;
    img {
      width: 68px;
      height: 80px;
    }
  }
  .routineTitle {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 17px;
    color: #212727;
    padding-top: 10px;
    align-items: center;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    // gap: 20px;
  }
  .productInnerWrap {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    // gap: 10px;
    margin: 5px 5px;
    background: #fff;
    border: 1px solid #eb008b;
    padding: 20px;
    justify-content: space-between;
    text-align: center;
    border-radius: 10px;
    width: 250px;
  }
  .buyBtn {
    font-weight: 400;
    font-size: 1rem;
    border: none;
    background: #eb008b;
    color: #F8F4EE;
    border-radius: 20px;
    // align-self: flex-start;
    text-align: center;
    padding: 7px 15px;
    text-decoration: none;
  }
  .priorityText {
    font-weight: 400;
    font-size: 1rem;
    color: #2F2F2F;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: flex-end;
  }
  .priorityBox {
    border: 1px solid #eb008b;
    padding: 10px 30px;
    border-radius: 10px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    align-items: center;
    // gap: 10px;
  }
  .colorCodeGreen {
    display: inline-block;
    background: #acfc8f;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 10px;
  }
  .colorCodeYellow {
    display: inline-block;
    background: #fdee8b;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 10px;
  }
  .colorCodeRed {
    display: inline-block;
    background: #fc5656;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 10px;
  }
  .accoWrap {
    background-color: rgba(72, 69, 66, 0.2);
    color: #212727;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Mabry' !important;
    p {
      font-family: 'Mabry' !important;
      font-weight: 700;
    }
    svg {
      color: #212727;
      margin-right: 20px;
    }
  }
`;

export default ResultStyle;