import styled from 'styled-components';

const AnalysisStyle = styled.div`
  padding: 0 30px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  // min-height: 60vh;

.titleWrapper{
  padding-bottom: 60px;
}
  .formWrapper {
  margin-bottom: 30px;
}
  .pageTitle {
    font-weight: 700;
    font-size: 2rem;
    line-height: 40px;
    color: #eb008b;
    font-family: 'Mabry';
    position: relative;
    // &:after {
    //   content:'';
    //   width: 35px;
    //   border-bottom: 2px solid #eb008b;
    //   position: absolute;
    //   bottom: -3px;
    //   left: 0px;
    // }
  }
  .questionText {
    font-family: 'Mabry';
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 24px;
    color: #2F2F2F;
    padding-bottom: 50px;
  }
  .questionSubtitle {
    font-size: 0.9rem;
  }
  .quesWrapper {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    // gap: 20px;
    justify-content: center;
  }
  .optLabel {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 20px;
    // color: #fff;
    width: 190px;
    height: 70px;
    // background: #eb008b;
    // border: 1px solid #eb008b;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 10px;
    cursor: pointer;
    input {
      display: none;
    }
  }
  input[name="skin_type"]+span, input[name="gender"]+span{
    border: 1px solid #eb008b;
    width: inherit;
    height: inherit;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    
  }
  input[name="skin_type"]:checked+span, input[name="gender"]:checked+span {
    border: 4px solid #eb008b;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .nextBtn {
    padding-top: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .backBtn {
    padding: 0px 50px;
    height: 60px;
    background: #eb008b;
    border-radius: 30px;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 19px;
    color: #F8F4EE;
    border: none;
    &:hover {
      border: 2px solid #F8F4EE;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .errorText{
    margin-top: 40px;
    margin-bottom: 10px;
    color: #ef0b0b;
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .SubmitBtn {
    padding: 0px 50px;
    height: 60px;
    background: #eb008b;
    border-radius: 30px;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 19px;
    color: #F8F4EE;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: 1px solid #F8F4EE;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .disabledBtn {
    padding: 0px 50px;
    height: 60px;
    background: #ef89c6;
    border-radius: 30px;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 19px;
    color: #F8F4EE;
    border: none;
    opacity: 0.7;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .operatorBtn {
    width: 100px;
    height: 100px;
    font-weight: 400;
    font-size: 2rem;
    line-height: 24px;
    color: #F8F4EE;
    background: #eb008b;
    border:none;
    &:hover {
      border: 4px solid #eb008b;
      box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
    }
  }
  .ageText{
    font-weight: 700;
    font-size: 2.3rem;
    line-height: 44px;
    color: #F8F4EE;
    letter-spacing: 0.01em;
    width: 50px;
    text-align: center;
  }
  .birthWrapper{
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }
  .cameraOutline {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  }
  .cameraOverlay {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 446px;
    height: 498px;
    border: 2px solid #F8F4EE;
    border-radius: 38px;
  }
  .cameraCaptured {
    width: 360px;
    height: auto;
    border-radius: 21px;
    border: 1px solid #F8F4EE;
  }
  .captureBtn {
    font-family: 'Mabry';
    background: #eb008b;
    border-radius: 30px;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 19px;
    color: #F8F4EE;
    border: none;
    padding: 20px 57px;
    margin-top: 60px;
    &:hover {
      border: 1px solid #F8F4EE;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .capturedImgOutline {
    width: 360px;
    height: auto;
    margin: auto;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    border-radius: 21px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
  }
  .galleryBtn {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #212727;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  .fileBtn{
    display: none;
  }
  .cameraIns {
    text-align: center;
    padding: 0 0 20px;
    font-size: 1.1rem;
    font-weight: 400;
    color: #212727;
    line-height: 20px;
  }
  .emailInput {
    font-family: 'Mabry' !important;
    width: 400px;
    height: 50px;
    background: #F8F4EE;
    border: none;
    padding: 15px;
    border-radius: 15px;
    font-size: 1.2rem;
    color: #2F2F2F;
    &:focus-visible {
      border: none;
      outline: none;
      -webkit-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      -moz-box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
      box-shadow: 0px 0px 10px 5px rgba(172,202,195,0.75);
    }
    
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

  }
  .divider {
    color: #F8F4EE;
    font-size: 1.2rem;
    text-align: center;
    padding: 30px;
    position: relative;
    width: 500px;
    margin: 0 auto;
  }
  .dividerLeft{
    &:before {
      content: "";
      height: 1px;
      width: 190px;
      position: absolute;
      background: #F8F4EE;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
    }
    // &:after {
    //   content: "";
    //   height: 1px;
    //   width: 190px;
    //   position: absolute;
    //   background: #F8F4EE;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   right: 40px;
    // }
  }
  .timerText {
    position: absolute;
    z-index: 1;
    top: 40%;
    transform: translate(0px, -50%);
    font-size: 3.5rem;
    color: #eb008b;
    font-weight: 700;
  }
    .smart-capture-container {
    width: clamp(450px, 450px, 90vw);
    border: 1px solid #eb008b;
}
`;

export default AnalysisStyle;