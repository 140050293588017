// import logo from './logo.svg';
import Header from './components/Header';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import Language from './components/Language';
import Analysis from './components/Analysis';
import Result from './components/Result';
import './App.css';

const AppRoute = () => {
  let routes = useRoutes([
    { path: "/", element: <Analysis /> },
    // { path: "result", element: <Result /> },
  ]);
  return routes;
};

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Language /> */}
      <Router>
        <AppRoute />
      </Router>
      
    </div>
  );
}

export default App;
