import React from "react";
import Logo from '../../images/logo.svg';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import HeaderStyle from "./style";
import { Box, Stack } from "@mui/material";

function Header() {
  return (
    <HeaderStyle>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <ChevronLeftOutlinedIcon sx={{ color: '#eb008b', fontSize: '30px' }} onClick={() => { window.location.reload() }} />
        <img src="orbo_logo_black.webp" alt="Logo" onClick={() => { window.location.reload() }}></img>
        <h1>Orbo Skin Analysis with Recommendations</h1>
      </Stack>
    </HeaderStyle>
  )
}

export default Header;