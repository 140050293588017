import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import useTranslation from "../../hooks/LanguageHooks";
import Result from "../Result";
import Loader from "../Loader";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { fetchResult, b64toBlob } from '../../services/analysisService';
import AnalysisStyle from "./style";
import { Stack } from "@mui/material";

function Analysis() {
  const translation = useTranslation();
  const [step, setStep] = useState(1);
  const [age, setAge] = useState(30);
  const [imgsrc, setImgsrc] = useState(null);
  const [skinType, setSkinType] = useState(null);
  const [gender, setGender] = useState(null);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(null);
  const [timer, setTimer] = React.useState(null);
  const [isSmartCaptureInprogress, setSmartCaptureInprogress] = React.useState(false);

  React.useEffect(() => {
    if (timer === 0) {
      setTimer(null);
    }
    if (!timer) return;
    const interval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);



  const submit = (value) => {
    setStep(step + 1);
    if (value.skin_type) {
      setSkinType(value.skin_type);
    } if (value.gender) {
      setGender(value.gender);
    }

  }

  // const decreamentClicked = () => {
  //   setAge(age-1);
  // }
  // const increamentClicked = () => {
  //   setAge(age+1);
  // }

  const selectAge = (e) => {
    setAge(e.target.value)
  }

  const videoConstraints = {
    facingMode: "user",
    borderRadius: "30px",
    aspectRatio: 0.8,
    width: { min: 500 },
    height: { min: 500 }
  };

  const backtoCamera = () => {
    setImgsrc(null);
  }



  async function captureImage(imageBlob) {
    // console.log(imageSrc)
    setLoading(true);
    setImgsrc(URL.createObjectURL(imageBlob));
    const formData = new FormData();
    formData.append('image', imageBlob);
    formData.append('skin_type', skinType);
    formData.append('age', age);
    formData.append('gender', gender);
    fetchResult(formData)
      .then(
        response => {
          setLoading(false);
          URL.revokeObjectURL(imgsrc);
          // console.log(response)
          if (response.statusCode === 200) {
            setStep(step + 1);
            setRes(response.data);
            localStorage.setItem('id', response.data.id);
          } else {
            setRes(response);
          }
        }
      );
  }

  const ageOption = () => {
    let arr = [];
    for (let i = 12; i <= 80; i++) {
      arr.push(i);
    }
    return arr;
  };

  const uploadFile = (event) => {
    let file = event.target.files[0];
    captureImage(file);
  };

  const startSmartCapture = async () => {
    setSmartCaptureInprogress(true);
    await window.eagle.initialize();
    const $container = document.getElementById("camera-container");
    window.eagle.startSmartCapture($container, (imageBlob) => {
      captureImage(imageBlob);
      setSmartCaptureInprogress(false);
    });
  };

  return (
    <AnalysisStyle>
      {step === 1 ? (<>
        {/* <ConcentDailog /> */}
        <div className="titleWrapper">
          <div className="pageTitle">{translation.ques1.quesType}</div>
        </div>
        <div className="formWrapper">
          <div className="questionText">{translation.ques1.quesTitle}</div>
          <Form
            onSubmit={submit}
            initialValues={{}}
          >
            {({ handleSubmit, pristine, form }) => (
              <form onSubmit={handleSubmit} >
                <div className="quesWrapper">
                  {Object.keys(translation.ques1.options).map((ele, idx) => (
                    <label key={idx} className="optLabel">
                      <Field
                        className="optBtn"
                        name="skin_type"
                        component="input"
                        type="radio"
                        value={ele}
                      />

                      <span className="">{translation.ques1.options[ele]}</span>
                    </label>
                  ))}
                </div>
                <div className="nextBtn">
                  <button
                    className={pristine ? "disabledBtn" : "SubmitBtn"}
                    type="submit"
                    disabled={pristine}
                  >
                    {translation.next}
                  </button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </>
      ) : step === 2 ?
        <>
          <div className="titleWrapper">
            <div className="pageTitle">{translation.ques2.quesType}</div>
          </div>
          <div className="formWrapper">
            <Form
              onSubmit={submit}
              initialValues={{}}
            >
              {({ handleSubmit, pristine, invalid, values, submitting, form }) => (
                <form onSubmit={handleSubmit} >
                  <div className="quesWrapper">
                    {Object.keys(translation.ques2.options).map((ele, idx) => (
                      <label key={idx} className="optLabel">
                        <Field
                          className="optBtn"
                          name="gender"
                          component="input"
                          type="radio"
                          value={ele}
                        />

                        <span className="">{translation.ques2.options[ele]}</span>
                      </label>
                    ))}
                  </div>
                  <div className="nextBtn">
                    <button
                      className={pristine || (values.gender && values.gender.length) === 0 ? "disabledBtn" : "SubmitBtn"}
                      type="submit"
                      disabled={pristine}
                    >
                      {translation.next}
                    </button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </> : step === 3 ?
          <>
            <div className="titleWrapper">
              <div className="pageTitle">{translation.ques3.quesType}</div>
            </div>
            <div className="formWrapper">
              <div className="questionText">{translation.ques3.quesTitle}</div>
              <Form
                onSubmit={submit}
                initialValues={{}}
              >
                {({ handleSubmit, pristine, invalid, values, submitting, form }) => (
                  <form onSubmit={handleSubmit} >
                    <div className="quesWrapper">
                      <Field name="subCategory">
                        {({ input, meta }) => (

                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            className="emailInput selectItem"
                            // defaultOpen={true}
                            // label="Age"
                            onChange={selectAge}
                          >
                            {ageOption().map((ele, idx) => <MenuItem className="optionItems" key={idx} value={ele}>{ele}</MenuItem>)}
                          </Select>
                        )}
                      </Field>
                    </div>
                    <div className="nextBtn">
                      <button
                        className="SubmitBtn"
                        type="submit"
                      >
                        {translation.next}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </> :
          step === 4 ?
            <>
              <div className="titleWrapper">
                <div className="pageTitle">{translation.ques4.quesType}</div>
              </div>
              <div className="formWrapper">
                <div className="questionText">{translation.ques4.quesTitle}</div>
                {!imgsrc ?
                  (<>
                    {!isSmartCaptureInprogress ?
                      <Stack spacing={2} direction="row" alignItems={"center"} justifyContent={"center"}>
                        <button className="SubmitBtn" type="button" onClick={startSmartCapture}>{translation.ques4.selfieBtn}</button>
                        <label className="SubmitBtn" type="button">{translation.ques4.galleryBtn}
                          <input className="fileBtn" type="file" accept="image/*" onChange={uploadFile} />
                        </label>
                      </Stack> :
                      <div id="camera-container" className="smart-capture-container"></div>
                    }
                  </>) :
                  (<div className="capturedImgOutline">
                    <img src={imgsrc} alt="" className="cameraCaptured" />
                    {res && res.statusCode !== 200 ?
                      <>
                        <div className="errorText">{res && res.message}</div>
                        <button onClick={backtoCamera} className="backBtn">{translation.captureAgain}</button>
                      </>
                      :
                      null}
                  </div>)
                }
              </div>
            </>
            :
            step === 5 ?
              <Result analysisResult={res} />
              : null}

      {loading ? <Loader /> : null}
    </AnalysisStyle>
  )

}

export default Analysis;