import styled from 'styled-components';

const HeaderStyle = styled.div`
background-color: rgb(245,245,245);
padding: 10px 0;
  img {
    cursor: pointer;
    width: 5rem;
  }
`;

export default HeaderStyle;